import React from 'react'
import Layout from '../../components/Layout'
import InnerLayout from '../../components/InnerLayout'

import cx from 'classnames'

import * as styles from './slack.module.css'
import * as commonStyles from '../../styles/common-components.module.css'

export default function Home() {
    return (
        <Layout
            title="Kubevious Slack"
            forceFullLogo
            seoTitle="Kubevious :: Slack"
            description="Join our Slack workspace to reach out to our team and open-source community!"
        >
            <InnerLayout>
                <div className={styles.content}>
                    <h2>Join our Slack workspace to reach out to our team and open-source community!</h2>

                    <div className={styles.linksContainer}>
                        <a  className={cx(commonStyles.linkButton, styles.link)}
                            href="https://kubevious.slack.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Log In
                        </a>
                        <a  className={cx(commonStyles.linkButton, styles.link)}
                            href="https://join.slack.com/t/kubevious/shared_invite/zt-cubzy4mb-LoIdqxfWm28Ycha~MOS_QA"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Sign Up
                        </a>
                    </div>
                </div>
            </InnerLayout>
        </Layout>
    )
}
